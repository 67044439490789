import qs from 'qs';
import Slider, { Range } from 'rc-slider';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { animated } from 'react-spring';
import styled from 'styled-components';
import {
  BOLIGSOK_MUTATION,
  BOLIGSOK_UNSUBSCRIBE_MUTATION,
  BOLIGSOK_UPDATE_MUTATION
} from '../../../../client/__graphql__/mutations';
import { BOLIGSOK_BY_HASH_QUERY } from '../../../../client/__graphql__/queries';
import { SiteContext } from '../../../utils/context/SiteContext';
import { currency } from '../../../utils/format/currency';
import { useForm } from '../../../utils/hooks/useForm';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { Seo } from '../../../utils/seo/Index';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Container } from '../../layout/Container';
import { Section } from '../../layout/Section';
import { Thanks } from '../../layout/Thanks';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { ButtonGroupColumn } from '../../ui/button/ButtonGroupColumn';
import { Checkbox } from '../../ui/form/Checkbox';
import { FormRow } from '../../ui/form/Form';
import { Input } from '../../ui/form/Input';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { GoogleSearch } from './GoogleSearch';
import { PropertyTypeSelector } from './PropertTypeSelector';
import { RadioSelector } from './RadioSelector';
import { Modal } from '../../ui/modal/Modal';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';

const Boligsok = (props: { location: any }) => {
  const [posted, setPosted] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(0);
  const [distance, setDistance] = useState(15000);
  const [price, setPrice] = useState({ from: 1000000, to: 10000000 });
  const [size, setSize] = useState({ from: 30, to: 400 });
  const [showConfirm, setConfirm] = useState(false);
  const typelist: string[] = [];
  const [selectedTypes, setSelectedTypes] = useState(typelist);
  const [avmeld] = useMutation(BOLIGSOK_UNSUBSCRIBE_MUTATION);
  const [avmeldt, setAvmeldt] = useState(false);

  const {
    routeConfig,
    contextData: { google }
  }: any = React.useContext(SiteContext);
  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });
  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const queryDataFromLocation = React.useMemo(() => {
    return qs.parse(props.location.search.replace('?', ''));
  }, []);

  const [
    loadingExistingSearch,
    { data: existingSearch, loading: loadingExisting, error }
  ] = useLazyQuery(BOLIGSOK_BY_HASH_QUERY, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    let queryData: any = queryDataFromLocation;
    if (queryData.id) {
      loadingExistingSearch({
        variables: {
          hash: queryData.id
        }
      });
    }

    if (queryData.pfv && queryData.ptv) {
      setPrice({ from: Number(queryData.pfv), to: Number(queryData.ptv) });
    }
    if (queryData.sfv && queryData.stv) {
      setSize({ from: Number(queryData.sfv), to: Number(queryData.stv) });
    }
    if (queryData.mb) {
      setSelectedRooms(Number(queryData.mb));
    }
  }, []);

  useEffect(() => {
    if (!google || !existingSearch || !existingSearch.boligsokByHash) return;

    const { boligsokByHash: search } = existingSearch;
    if (search.pricefrom && search.priceto) {
      setPrice({
        from: Number(search.pricefrom),
        to: Number(search.priceto)
      });
    }
    if (search.sizefrom && search.sizeto) {
      setSize({
        from: Number(search.sizefrom),
        to: Number(search.sizeto)
      });
    }
    if (search.bedroomsfrom) {
      setSelectedRooms(Number(search.bedroomsfrom));
    }

    if (search.propertytype) {
      setSelectedTypes(search.propertytype);
    }

    if (search.geo) {
      window.gmap.setCenter({
        lat: search.geo.lat,
        lng: search.geo.lng
      });
      window.circle.setRadius(search.geo.radius);
      setDistance(search.geo.radius);
      window.gmap.fitBounds(window.circle.getBounds());
    }
    setAvmeldt(!search.active);
  }, [existingSearch, google]);

  const [submitData] = useMutation(BOLIGSOK_MUTATION);
  const [updateData] = useMutation(BOLIGSOK_UPDATE_MUTATION);

  const { fields, handleSubmit, handleChange, handleReset } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn',
        required: queryDataFromLocation.id ? false : true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '0000',
        value: '',
        label: 'Postnummer',
        required: queryDataFromLocation.id ? false : true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ola.nordmann@epost.no',
        value: '',
        label: 'E-mail',
        required: queryDataFromLocation.id ? false : true
      },
      {
        type: 'tel',
        name: 'mobile',
        placeholder: 'xx xx xx xx',
        value: '',
        label: 'Mobil',
        required: queryDataFromLocation.id ? false : true
      },
      {
        type: 'checkbox',
        checked: false,
        name: 'valuation',
        value: 'valuation',
        label:
          'i forbindelse med salg og verdivurdering / e-takst av nåværende eiendom',
        required: true
      }
    ],
    submit: async () => {
      const position = window.gmap.getCenter();
      const geo = {
        lat: position.lat(),
        lng: position.lng(),
        radius: distance
      };

      const contactinfo = fields
        .filter((item) => item.type !== 'submit' && item.type !== 'checkbox')
        .reduce((prev: any, cur: any, i: number) => {
          return { ...prev, [cur.name]: cur.value };
        }, {});

      fields
        .filter((item) => item.type !== 'submit' && item.type !== 'checkbox')
        .reduce((prev: any, cur: any, i: number) => {
          return { ...prev, [cur.name]: cur.value };
        }, {});

      const consents = fields
        .filter((item) => item.type === 'checkbox')
        .reduce((prev: any, cur: any, i: number) => {
          return { ...prev, [cur.name]: cur.checked };
        }, {});

      setIsPosting(true);

      try {
        let resp: any = null;
        if (existingSearch) {
          let queryData: any = queryDataFromLocation;

          resp = await updateData({
            variables: {
              input: {
                coordinates: geo,
                price,
                size,
                bedroomsfrom: selectedRooms,
                propertytype: JSON.stringify(selectedTypes),
                hash: queryData.id
              }
            }
          });
        } else {
          resp = await submitData({
            variables: {
              input: {
                coordinates: geo,
                consents,
                price,
                size,
                bedroomsfrom: selectedRooms,
                propertytype: JSON.stringify(selectedTypes),
                contactinfo
              }
            }
          });
        }
        if (
          (resp.data.boligsokform &&
            resp.data.boligsokform.response === 'ok') ||
          (resp.data.updateBoligsokform &&
            resp.data.updateBoligsokform.response === 'ok')
        ) {
          handleReset();
          setIsPosting(false);
          setPosted(true);
          // vis takkemelding
          window.scrollTo(0, 0);
        }
      } catch (e) {
        setIsPosting(false);
        alert('det skjedde en feil');
      }
    }
  });

  const askConfirmation = (e: any) => {
    e.preventDefault();
    setConfirm(true);
  };

  const hideConfirmation = (e: any) => {
    e.preventDefault();
    setConfirm(false);
  };

  const unsubscribe = async (e: any) => {
    e.preventDefault();
    setConfirm(false);

    let queryData: any = queryDataFromLocation;

    try {
      const { data } = await avmeld({
        variables: {
          hash: queryData.id
        }
      });
      if (data.unsubscribe.response === 'ok') {
        setAvmeldt(true);
      }
    } catch (e) {
      console.log('feil', e);
    }
  };

  const modifyPrice = ([from, to]: [number, number]) => {
    setPrice({ ...price, from, to });
  };
  const modifySize = ([from, to]: [number, number]) => {
    setSize({ ...size, from, to });
  };
  const modifyDistance = (value: number) => {
    setDistance(value);
  };

  return (
    <>
      <Seo
        title="Eiendomssøk - PrivatMegleren"
        description="Registrer ditt eiendomsøk hos PrivatMegleren, motta aktuelle eiendommer på epost. "
        image="https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg"
        imageTitle="register-ditt-eiendomssøk-PrivatMegleren"
        url=""
      />
      <Container
        className="padding-top"
        style={{ ...pageFadeIn, minHeight: '100vh' }}
      >
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              PrivatMegleren Eiendomssøk
            </Heading>

            {existingSearch ? (
              <>
                {avmeldt ? (
                  <div style={{ textAlign: 'center', color: '#fff' }}>
                    Du er avmeldt fra nyhetsbrevet
                  </div>
                ) : (
                  <>
                    <Paragraph center={true} className="form-text ingress">
                      Klikk på knappen under dersom du ønsker å melde deg av
                      PrivatMegleren Eiendomssøk.
                    </Paragraph>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        colorTheme="gold"
                        onClick={askConfirmation}
                      >
                        Meld meg av
                      </Button>
                    </div>
                  </>
                )}

                <AvmeldPortal id="myportal">
                  <Modal id="myportal" showModal={showConfirm} inline={true}>
                    <ConfirmUnsub>
                      Er du sikker på at du ønsker å melde deg av?
                      <ButtonGroup
                        position="center"
                        style={{ margin: '10px 0' }}
                      >
                        <ButtonGroupColumn>
                          <Button type="primary" onClick={unsubscribe}>
                            Ja
                          </Button>
                        </ButtonGroupColumn>
                        <ButtonGroupColumn>
                          <Button type="tertiary" onClick={hideConfirmation}>
                            Nei
                          </Button>
                        </ButtonGroupColumn>
                      </ButtonGroup>
                    </ConfirmUnsub>
                  </Modal>
                </AvmeldPortal>
              </>
            ) : (
              <Paragraph center={true} className="form-text ingress">
                PrivatMegleren Eiendomssøk holder deg ukentlig oppdatert med
                aktuelle eiendommer. Du kan enkelt melde deg av eiendomssøket
                via link nederst i de ukentlige oppdateringene fra oss.
              </Paragraph>
            )}

            <Ordered style={{ display: existingSearch ? 'none' : '' }}>
              <li>
                <OrderedSectionHeader>Type eiendom</OrderedSectionHeader>
                <OrderedSection>
                  <PropertyTypeSelector
                    location={props.location}
                    setSelectedTypes={setSelectedTypes}
                    selectedTypes={selectedTypes}
                  />
                  <SliderComponent>
                    <label>Pris</label>
                    <SliderWrap>
                      <Range
                        min={1000000}
                        max={10000000}
                        step={100000}
                        onChange={modifyPrice}
                        value={[price.from, price.to]}
                        railStyle={{ backgroundColor: '#252525' }}
                        trackStyle={[{ backgroundColor: '#e8c893' }]}
                        handleStyle={[
                          {
                            backgroundColor: '#000',
                            borderColor: '#e8c893',
                            borderWidth: '3px',
                            marginLeft: '6px'
                          },
                          {
                            backgroundColor: '#000',
                            borderColor: '#e8c893',
                            borderWidth: '3px'
                          }
                        ]}
                      />
                    </SliderWrap>
                    <ValueInfo>
                      {price.from === 1000000 && 'Under'}{' '}
                      {currency({ number: price.from, seperator: ' ' })} -{' '}
                      {price.to === 10000000 && 'Over'}{' '}
                      {currency({ number: price.to, seperator: ' ' })},-
                    </ValueInfo>
                  </SliderComponent>
                  <SliderComponent>
                    <label>Størrelse</label>
                    <SliderWrap>
                      <Range
                        min={30}
                        max={400}
                        step={5}
                        onChange={modifySize}
                        value={[size.from, size.to]}
                        railStyle={{ backgroundColor: '#252525' }}
                        trackStyle={[{ backgroundColor: '#e8c893' }]}
                        handleStyle={[
                          {
                            backgroundColor: '#000',
                            borderColor: '#e8c893',
                            borderWidth: '3px',
                            marginLeft: '6px'
                          },
                          {
                            backgroundColor: '#000',
                            borderColor: '#e8c893',
                            borderWidth: '3px'
                          }
                        ]}
                      />
                    </SliderWrap>
                    <ValueInfo>
                      {size.from === 30 && 'Under'} {size.from} -{' '}
                      {size.to === 400 && 'Over'} {size.to}
                      m&sup2;
                    </ValueInfo>
                  </SliderComponent>
                  <OrderedSectionHeader>Antall soverom</OrderedSectionHeader>
                  <RadioSelector
                    selected={selectedRooms}
                    setSelected={setSelectedRooms}
                  />
                </OrderedSection>
              </li>
              <li>
                <OrderedSectionHeader>Område</OrderedSectionHeader>
                <OrderedSection>
                  <GoogleSearch distance={distance} />

                  <SliderWrap>
                    <Slider
                      min={100}
                      max={30000}
                      step={100}
                      value={distance}
                      onChange={modifyDistance}
                      railStyle={{ backgroundColor: '#252525' }}
                      trackStyle={[{ backgroundColor: '#e8c893' }]}
                      handleStyle={[
                        {
                          backgroundColor: '#000',
                          borderColor: '#e8c893',
                          borderWidth: '3px',
                          marginLeft: '6px'
                        },
                        {
                          backgroundColor: '#000',
                          borderColor: '#e8c893',
                          borderWidth: '3px'
                        }
                      ]}
                    />
                  </SliderWrap>
                  <ValueInfo>
                    {distance > 1000 ? distance / 1000 : distance}
                    {distance > 1000 ? ' km' : ' m'} radius
                  </ValueInfo>
                </OrderedSection>
              </li>
              {!existingSearch ? (
                <li>
                  <Heading tag="h2" center={true}>
                    Din kontaktinformasjon
                  </Heading>
                  <OrderedSection>
                    <form onSubmit={handleSubmit}>
                      {fields.map(
                        (item, i) =>
                          item.type !== 'checkbox' && (
                            <FormRow key={i}>
                              <Input {...item} onChange={handleChange} />
                            </FormRow>
                          )
                      )}
                      <FormRow>
                        Jeg anmoder PrivatMegleren om å kontakte meg på
                        telefon/sms/e-post
                      </FormRow>
                      <FormRow>
                        <Checkbox
                          labelPos="right"
                          {...fields[4]}
                          onChange={handleChange}
                        />
                      </FormRow>
                      <FormRow>
                        <ButtonGroup>
                          <Button
                            type="primary"
                            colorTheme="gold"
                            disabled={posted}
                            loading={isPosting}
                          >
                            Lagre
                          </Button>
                          <ButtonLink
                            as="a"
                            href={routeConfig.personvern.url}
                            className="privacy-link-mobile"
                          >
                            Personvernpolicy
                          </ButtonLink>
                        </ButtonGroup>
                      </FormRow>
                    </form>
                  </OrderedSection>
                </li>
              ) : (
                !avmeldt && (
                  <OrderedSection>
                    <form onSubmit={handleSubmit}>
                      <FormRow>
                        <ButtonGroup>
                          <Button
                            type="primary"
                            disabled={posted}
                            loading={isPosting}
                          >
                            Oppdater søk
                          </Button>
                          <ButtonLink
                            as="a"
                            href={routeConfig.personvern.url}
                            className="privacy-link-mobile"
                          >
                            Personvernpolicy
                          </ButtonLink>
                        </ButtonGroup>
                      </FormRow>
                    </form>
                  </OrderedSection>
                )
              )}
            </Ordered>
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              PrivatMegleren eiendomssøk holder deg ukentlig oppdatert med
              aktuelle eiendommer.
            </Heading>
            <Paragraph center={true} className="ingress">
              Én gang i uken vil du få tilsendt aktuelle eiendommer med kommende
              visninger pr. e-post. Du kan når som helst melde deg av
              Eiendomssøket via en link nederst i e-postene.
            </Paragraph>
            <Paragraph center={true}>
              Hvis du ønsker å lese mer om salg, vurdering av egen eiendom,
              og/eller andre produkter fra PrivatMegleren, klikk på linkene
              under.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/cbimage.jpg"
      />
    </>
  );
};

export default Boligsok;

const Ordered = styled.ul`
  padding: 0;
  margin: 0 1em;
  color: #fff;
  list-style-type: none;
`;

const OrderedSectionHeader = styled.div`
  font-weight: 300;
  margin: 4em 0 10px;
`;

const OrderedSection = styled(Section)`
  padding: 0;
`;

const ValueInfo = styled.div`
  text-align: center;
`;

const SliderComponent = styled.div`
  margin: 1em 0 2em;
`;

const SliderWrap = styled.div`
  margin: 10px 0;
`;

const AvmeldPortal = styled.div`
  position: relative;
`;

const FormWrapper = styled(animated.div)``;

const ConfirmUnsub = styled.div`
  background-color: #333;
  color: #fff;
  position: absolute;
  width: 300px;
  padding: 1em;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  text-align: center;
`;
